import isEmpty from 'lodash/isEmpty'
import config from 'config'

import { stringifyQuery } from './parse-querystring'

export default function makeMobileUrl (appAbbr, path, params) {
  const isDev = config.ENV !== 'production'

  // appAbbr = am || sc
  let scheme = appAbbr === 'sc' ? 'staycircles' : 'amsc'
  if (isDev) {
    scheme = scheme + 'stag'
  }

  // Add app standalone url considering environment
  let url = `${scheme}://`

  if (path) {
    url = `${url}${path}`
  }

  if (!isEmpty(params)) {
    const paramsString = stringifyQuery(params)
    url = `${url}?${paramsString}`
  }

  return url
}
