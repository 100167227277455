import React from 'react'

import get from 'lodash/get'

import root from 'utils/windowOrGlobal'
import isMobileDevice from 'utils/isMobileDevice'
import makeMobileUrl from 'utils/makeMobileUrl'

import { navigate } from 'gatsby'
import { Location, Link as ReachLink } from '@reach/router'

import * as routes from 'config/routes'

import styled from 'styled-components'

import colors from 'styles/colors'

import { Form } from 'react-final-form'

import { required } from 'utils/field-validators'

import queryString from 'utils/parse-querystring'

import { AuthProvider } from 'stores/auth'
import SignUpStoreProvider from './_store'

import Layout from 'components/Layout'
import { headerHeight } from 'components/Header'
import Icon from 'components/Icon'
import Input, { ErrorText } from 'components/Input'
import Button from 'components/Button'

import { PasswordInput } from 'components/AuthQueryModals/Login'

const DefaultSuccessView = () => (
  <div className='center flex flex-column items-center w-100 w-90-ns tc'>
    <img
      src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,c_crop,w_1860/c_scale,w_300/assets/hangout_i3gbth.png'
      alt='Welcome to StayCircles!'
    />

    <h1 className='tc lh-title'>Welcome to StayCircles!</h1>

    <p className='tc lh-copy' style={{ color: colors.blackRGBA(0.7) }}>
      Redirecting...
    </p>
  </div>
)

const MobileSuccessView = () => (
  <div className='center flex flex-column items-center w-100 w-90-ns tc'>
    <img
      src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,c_crop,w_1860/c_scale,w_300/assets/hangout_i3gbth.png'
      alt='Welcome to StayCircles!'
    />

    <h1 className='tc lh-title'>Welcome to StayCircles!</h1>

    <p className='tc lh-copy' style={{ color: colors.blackRGBA(0.7) }}>
      Open the app and login with your account
    </p>

    <LargeButton
      type='button'
      onClick={() => openAppUrl('sc')}
      color={colors.white}
      colorHover={colors.offwhite}
      bgColor={colors.blue}
      bgColorHover={colors.darkBlue}
      expand
      expandWidth='100%'
    >
      {isMobileDevice() ? 'OPEN APP' : 'DOWNLOAD APP'}
    </LargeButton>
  </div>
)

const AMSuccessView = () => (
  <div className='center flex flex-column items-center w-100 w-90-ns tc'>
    <img
      src='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,c_crop,h_1365,w_1200/c_scale,w_250/assets/am_4_eqbfnu.png'
      alt='Welcome to Adventure Machine!'
    />

    <h1 className='tc lh-title'>You're ready to get epic cheap flight deals</h1>

    <p className='tc lh-copy' style={{ color: colors.blackRGBA(0.7) }}>
      Welcome to Adventure Machine!
      <br />
      Open the app and login with your account
    </p>

    <LargeButton
      type='button'
      onClick={() => openAppUrl('am')}
      color={colors.white}
      colorHover={colors.offwhite}
      bgColor={colors.blue}
      bgColorHover={colors.darkBlue}
      expand
      expandWidth='100%'
    >
      {isMobileDevice() ? 'OPEN APP' : 'DOWNLOAD APP'}
    </LargeButton>
  </div>
)

const DefaultErrorView = ({ rootPath, isLoggedIn }) => (
  <div className='center flex flex-column items-center w-100 w-90-ns mv4 tc'>
    <p className='tc lh-copy' style={{ color: colors.blackRGBA(0.7) }}>
      You have already signed up to StayCircles.{' '}
      {!isLoggedIn() && (
        <Link to={`${rootPath}${routes.LOGIN}`}>Login here</Link>
      )}
    </p>
  </div>
)

const SignUpModal = () => (
  <Layout>
    <Location>
      {({ location }) => {
        const query = queryString(location.search)
        const signUpToken = get(query, 'confirmation_code')
        const invitationToken = get(query, 'invitation_code')

        return (
          <AuthProvider>
            {auth => (
              <SignUpStoreProvider {...{ signUpToken, invitationToken }}>
                {store => {
                  const { data, error } = store.state

                  const email = decodeURIComponent(store.state.email)

                  const app = get(data, 'openSignupBy')
                  const isAM = app === 'am_app'
                  const isMobile = app === 'sc_app'
                  if (store?.state?.machineState?.value === 'checkingToken') {
                    return null
                  }
                  return (
                    <Container isAM={isAM}>
                      <Content>
                        <Form
                          initialValues={{
                            email,
                            invitationCode: invitationToken,
                            signupCode: signUpToken
                          }}
                          onSubmit={(values, _, callback) => {
                            store.transition({
                              type: 'SUBMIT',
                              values,
                              callback: async (error, user) => {
                                if (user) {
                                  await auth.handleLogin(user, true)
                                }

                                callback(error)
                              }
                            })
                          }}
                          render={({
                            handleSubmit,
                            submitting,
                            invalid,
                            submitError,
                            submitSucceeded
                          }) => {
                            if (submitSucceeded) {
                              if (isAM) {
                                return <AMSuccessView />
                              }

                              if (isMobile) {
                                return <MobileSuccessView />
                              }

                              return <DefaultSuccessView />
                            }

                            if (error && error === 'signed_up') {
                              return (
                                <DefaultErrorView
                                  rootPath={location.pathname}
                                  isLoggedIn={auth.isLoggedIn}
                                />
                              )
                            }

                            if (
                              store.matchesActions('showError') &&
                              get(store, 'state.error')
                            ) {
                              return (
                                <div className='center flex flex-column items-center w-100 w-90-ns mv4'>
                                  <ErrorText center>
                                    {get(store, 'state.error')}
                                  </ErrorText>
                                </div>
                              )
                            }

                            const hasError = (invalid && submitError) || !!error

                            return (
                              <form
                                onSubmit={handleSubmit}
                                className='center flex flex-column items-center w-100 w-90-ns mb0'
                              >
                                <HeaderRow>
                                  <Logo />
                                  {isAM && <LogoSepLine />}
                                  {isAM && <AMLogo />}
                                </HeaderRow>

                                <h1 className='mt0 mb4 lh-title'>
                                  Create your account
                                </h1>

                                <p
                                  className='w-100 tl mb3 lh-copy'
                                  style={{ color: colors.blackRGBA(0.7) }}
                                >
                                  <Icon
                                    name='email'
                                    height={20}
                                    className='mr2'
                                    style={{ color: colors.blackRGBA(0.25) }}
                                  />
                                  {email ||
                                    (hasError && 'Error') ||
                                    'Loading...'}
                                </p>

                                <Input
                                  id='firstName'
                                  name='firstName'
                                  placeholder='First name'
                                  autoComplete='given-name'
                                  validate={required}
                                  disabled={submitting}
                                />

                                <Input
                                  id='lastName'
                                  name='lastName'
                                  placeholder='Last name'
                                  autoComplete='family-name'
                                  validate={required}
                                  disabled={submitting}
                                />

                                <PasswordInput
                                  id='password'
                                  name='password'
                                  label='Create your password'
                                  autoComplete='current-password'
                                  validate={required}
                                  disabled={submitting}
                                  helperText='At least 8 characters'
                                />

                                {hasError && (
                                  <ErrorText
                                    center
                                    style={{ marginBottom: '2rem' }}
                                  >
                                    {error && submitError}
                                  </ErrorText>
                                )}

                                <LargeButton
                                  className='mv3'
                                  type='submit'
                                  color={colors.white}
                                  colorHover={colors.offwhite}
                                  bgColor={colors.red}
                                  bgColorHover={colors.darkRed}
                                  disabled={submitting || !!error}
                                  expand
                                  expandWidth='100%'
                                >
                                  {submitting ? 'SIGNING YOU UP...' : 'SIGN UP'}
                                </LargeButton>

                                <p
                                  className='tc lh-copy'
                                  style={{ color: colors.blackRGBA(0.7) }}
                                >
                                  By signing up you agree to StayCircles{' '}
                                  <br className='dn db-ns' />
                                  <Link
                                    as='a'
                                    href={routes.TERMS}
                                    target='_blank'
                                  >
                                    Terms of Use
                                  </Link>{' '}
                                  and{' '}
                                  <Link
                                    as='a'
                                    href={routes.PRIVACY}
                                    target='_blank'
                                  >
                                    Privacy Policy
                                  </Link>
                                </p>
                              </form>
                            )
                          }}
                        />
                      </Content>
                    </Container>
                  )
                }}
              </SignUpStoreProvider>
            )}
          </AuthProvider>
        )
      }}
    </Location>
  </Layout>
)

const nyImage = 'v1529056850/assets/applanding/nyc_pins_bg.jpg'
const rioImage = 'v1529055774/assets/applanding/rio_pins_bg.jpg'

const mediumSize =
  'https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,c_scale,w_2000,b_black,o_60/'
const largeSize =
  'https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,b_black,o_60/'

const getBackground = props => (props.isAM ? rioImage : nyImage)

const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - ${headerHeight});
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (min-width: 600px) {
    padding-top: 4rem;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('${mediumSize}${getBackground}');
  }

  @media (min-width: 2400px) {
    background-image: url('${largeSize}${getBackground}');
  }
`

const Content = styled.div`
  min-width: 240px;
  width: 90%;
  max-width: 520px;
  padding: 2rem;
  background-color: #fff;
  border-radius: 4px;

  @media (max-width: 767px) {
    width: 96%;
  }
`

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0 24px;

  @media (min-width: 600px) {
    margin: 0 0 32px;
  }
`

const Logo = styled(Icon).attrs({ name: 'sc-logo' })`
  color: #333;
  height: 32px;
`

const AMLogo = styled(Icon).attrs({ name: 'am-logo' })`
  height: 38px;
`

const LogoSepLine = styled.div`
  width: 1px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.1);
  transform: rotate(25deg);
  margin: 0 16px;
`

const LargeButton = styled(Button)`
  padding-left: 32px;
  padding-right: 32px;
  letter-spacing: 2px;

  @media (max-width: 480px) {
    width: 100%;
  }
`

const Link = styled(ReachLink).attrs({
  className: 'link underline-hover'
})`
  color: ${colors.blue};
`

export default SignUpModal

function navigateToDownloadPage (app) {
  if (app === 'am') {
    navigate('/adventuremachineapp')
  } else {
    navigate('/staycirclesapp')
  }
}

function openAppUrl (app) {
  const url = makeMobileUrl(app)

  if (!isMobileDevice()) {
    navigateToDownloadPage(app)
    return
  }

  const start = Date.now()

  root.setTimeout(() => {
    if (Date.now() - start < 110) {
      navigateToDownloadPage(app)
    }
  }, 100)

  root.location = url
}
