import React from 'react'
import PropTypes from 'prop-types'

import root from 'utils/windowOrGlobal'
import isMobileDevice from 'utils/isMobileDevice'
import makeMobileUrl from 'utils/makeMobileUrl'

import get from 'lodash/get'

import { navigate } from 'gatsby'

import * as routes from 'config/routes'

import { FORM_ERROR } from 'final-form'

import logger from 'utils/logger'

import StateMachineProvider, { StateMachineContainer } from 'utils/StateMachine'

import * as api from './_api'

const statechart = {
  initial: 'checkingToken',
  states: {
    checkingToken: {
      onEntry: ['showLoading'],
      on: {
        VALID: 'idle',
        FAILURE: 'error'
      }
    },
    idle: {
      on: {
        SUBMIT: 'loading'
      }
    },
    loading: {
      onEntry: ['showLoading', 'submitSignUp'],
      on: {
        SUCCESS: 'complete',
        FAILURE: 'error'
      }
    },
    error: {
      onEntry: 'showError',
      on: {
        SUBMIT: 'loading'
      }
    },
    complete: {}
  }
}

class SignUpContainer extends StateMachineContainer {
  constructor (props) {
    super(props)

    // Make sure to keep the state machine engine
    const stateMachine = this.state

    this.state = {
      ...stateMachine,
      token: null,
      data: {},
      email: null,
      error: null
    }
  }

  componentDidTransition (prevStateMachine, event) {
    if (event && event.type) {
      switch (event.type) {
        case 'FAILURE':
          this.setError(event)
          break
        case 'VALID':
        case 'SUCCESS':
          this.setSuccess(event)
          break
      }
    }
  }

  setSuccess = async event => {
    if (event.callback) {
      const user = get(event, 'data.user')
      event.callback(undefined, user)

      const app = get(this.state, 'data.openSignupBy')
      if (!['am_app', 'sc_app'].includes(app)) {
        setTimeout(() => {
          navigate(`${routes.APP}`)
        }, 2000)
      }
    } else {
      this.setState({ data: event.data })
    }
  }

  setError = event => {
    let error = event.error

    if (Array.isArray(error)) {
      error = error[0]
    }

    if (event.callback) {
      event.callback({ [FORM_ERROR]: error })
    } else {
      if (error && error.match(/already/g)) {
        this.setState({ error: 'signed_up' })
      } else {
        this.setState({ error })
      }
    }
  }

  submitSignUp = async ({ values, callback, ...event }) => {
    const { token } = this.state

    logger.captureBreadcrumb({
      message: 'SignUpScreen.submit',
      category: 'auth',
      data: { token }
    })

    try {
      const [error, data] = await api.createAccount(values)

      if (error) {
        logger.captureException(error)
        this.transition({ type: 'FAILURE', error, callback })
      } else {
        logger.captureBreadcrumb({
          message: 'SignUpScreen.success',
          category: 'auth',
          data: { token }
        })

        this.transition({ ...event, type: 'SUCCESS', data, callback })
      }
    } catch (error) {
      logger.captureException(error)
    }
  }

  checkToken = async (apiCall, token) => {
    logger.captureBreadcrumb({
      message: 'SignUp.checkToken ' + apiCall,
      category: 'auth',
      data: { token }
    })

    this.setState({ token })

    try {
      const [error, data] = await api[apiCall](token)

      if (error) {
        logger.captureException(error)

        this.transition({ type: 'FAILURE', error })
        return null
      } else {
        logger.captureBreadcrumb({
          message: 'SignUp.checkToken.success ' + apiCall,
          category: 'auth',
          data
        })

        return data
      }
    } catch (error) {
      logger.captureException(error)
    }
  }

  checkSignupToken = async token => {
    const data = await this.checkToken('checkSignupToken', token)
    if (data) {
      if (get(data, 'email')) {
        const email = decodeURIComponent(data.email)
        this.setState({ email })

        // Redirect to mobile apps if apply
        const app = get(data, 'openSignupBy')
        if (['am_app', 'sc_app'].includes(app) && isMobileDevice()) {
          handleMobileRedirect(app, email, token)
        }
      }

      this.transition({ type: 'VALID', data })
    }
  }

  checkInvitationToken = async token => {
    const data = await this.checkToken('checkInvitationToken', token)

    if (get(data, 'associatedEmail')) {
      const email = decodeURIComponent(data.associatedEmail)
      this.setState({ email })
    }

    this.transition({ type: 'VALID', data })
  }
}

export default class SignUpStoreProvider extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.container = new SignUpContainer({ statechart })
  }

  componentDidMount () {
    const { signUpToken = null, invitationToken = null } = this.props
    if (!signUpToken && !invitationToken) {
      navigate(`${routes.SIGNUP}`)
      return
    }
    if (invitationToken) {
      this.container.checkInvitationToken(invitationToken)
    } else {
      this.container.checkSignupToken(signUpToken)
    }
  }

  render () {
    return (
      <StateMachineProvider container={this.container}>
        {machineStore => this.props.children(machineStore)}
      </StateMachineProvider>
    )
  }
}

/**
 * Helpers
 */

function handleMobileRedirect (app, email, token) {
  email = root.encodeURIComponent(email).replace(/%/g, '%25')

  // app = am_app || sc_app
  const appAbbr = app === 'sc_app' ? 'sc' : 'am'

  const params = { email, signupCode: token }

  const url = makeMobileUrl(appAbbr, 'signup', params)

  root.location = url
}
